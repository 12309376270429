import type { GetWidgetManifestFn } from '@wix/yoshi-flow-editor/blocks';
import { SinglePlanWidgetRole } from '../../constants/elements';
import { configureTextElement } from '../../utils/widget';

export const getWidgetManifest: GetWidgetManifestFn = (builder, editorSDK, flowAPI) => {
  const t = flowAPI.translations.t;

  builder.set({ displayName: t('blocks.label.pricing') });

  builder.configureConnectedComponents(SinglePlanWidgetRole.Price, (priceBuilder) => {
    priceBuilder.set({ displayName: t('blocks.pricing.design.price') });
    configureTextElement({
      builder: priceBuilder,
      editorSDK,
      flowAPI,
      editTextLabel: t('blocks.pricing.gfpp.edit-text'),
      designTextLabel: t('blocks.benefits.text.design'),
    });
  });

  builder.configureConnectedComponents(SinglePlanWidgetRole.Currency, (currencyBuilder) => {
    currencyBuilder.set({ displayName: t('blocks.pricing.design.currency') });
    configureTextElement({
      builder: currencyBuilder,
      editorSDK,
      flowAPI,
      editTextLabel: t('blocks.pricing.gfpp.edit-text'),
      designTextLabel: t('blocks.benefits.text.design'),
    });
  });

  builder.configureConnectedComponents(SinglePlanWidgetRole.Frequency, (frequencyBuilder) => {
    frequencyBuilder.set({ displayName: t('blocks.pricing.design.frequency') });
    configureTextElement({
      builder: frequencyBuilder,
      editorSDK,
      flowAPI,
      editTextLabel: t('blocks.pricing.gfpp.edit-text'),
      designTextLabel: t('blocks.benefits.text.design'),
    });
  });

  builder.configureConnectedComponents(SinglePlanWidgetRole.SetupFee, (setupFeeBuilder) => {
    setupFeeBuilder.set({ displayName: t('blocks.pricing.design.setup-fee') });
    configureTextElement({
      builder: setupFeeBuilder,
      editorSDK,
      flowAPI,
      editTextLabel: t('blocks.pricing.gfpp.edit-text'),
      designTextLabel: t('blocks.benefits.text.design'),
    });
  });
};
